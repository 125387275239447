<template>
	<div>
		<div v-show="isSetDestination" @click="handleCompassClick">
			<v-icon size="25" class="compass-icon align-self-center" :style="compassStyle">mdi-chevron-up</v-icon>
		</div>
		<gmap-map ref="gmap" id="gmap" :center="center" :zoom="zoom" :options="mapOptions" @tilesloaded="onMapLoaded" style="width: 100%; height: calc(100vh - 114px); position: relative;">
			<gmap-polygon v-if="$store.state.principal.activity && $store.state.principal.activity.start_area && !$store.state.isCompletedStartArea" :paths="start" :options="{ strokeWeight: 1, fillColor: '#3F51B5', fillOpacity: 1 }"> </gmap-polygon>
			<gmap-marker v-if="!$store.state.isCompletedStartArea && startMarker.lat" :clickable="true" :draggable="false" @click="toggleInfo(startMarker, 'start', $t('the_start_area'))" :position="startMarker"> </gmap-marker>
			<gmap-marker v-if="$store.state.player.position" :position="$store.state.player.position" :icon="{ url: 'team_icon_me.png' }" :zIndex="999"> </gmap-marker>
			
			<template v-if="$store.state.isStarted && $store.state.isCompletedStartArea">
				<gmap-polygon :paths="forbidden" :options="{ strokeWeight: 0.5, fillColor: '#F33', fillOpacity: 0.5 }"> </gmap-polygon>
				<gmap-polygon v-if="$store.state.isFinished" :paths="finish" :options="$store.state.isFinished ? { strokeWeight: 1, fillColor: '#3F51B5', fillOpacity: 1 } : {}"> </gmap-polygon>
				<gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false">
					<div v-html="infoOptions.content"></div>
				</gmap-info-window>
				<!-- <gmap-marker v-if="currentPosition" :position="currentPosition" :icon="{ url: 'team_icon_me.png' }" :zIndex="999"></gmap-marker> -->
				<gmap-marker v-if="$store.state.isFinished && finishMarker.lat" :clickable="true" :draggable="false" @click="toggleInfo(finishMarker, 'finish', $t('the_finish_area'))" :position="finishMarker"> </gmap-marker>
				<gmap-marker v-for="(forbidden, index) in forbiddenMarker" :clickable="true" :draggable="false" @click="toggleInfo(forbidden, 'forbidden', $t('forbidden_area'))" :position="forbidden" :key="999 * index"> </gmap-marker>
				<template v-if="!$store.state.isFinished">
					<gmap-marker
						v-for="(m, index) in checkPointList"
						:key="'111' + index"
						:position="m.position"
						:icon="{ url: m.image ? m.image : 'floating_checkpoint.png', scaledSize: { width: 50, height: m.image ? m.scale * 50 : 80, f: '%', b: '%' } }"
						:clickable="true"
						:draggable="false"
						@click="handleMarkerClick(m, index)"
					></gmap-marker>
					<gmap-circle
						v-for="(m, index) in checkPointList"
						:key="'112' + index"
						:center="m.position"
						:radius="m.radius"
						:options="{ fillColor: '#F33', fillOpacity: 0.5, strokeWeight: 1, strokeColor: '#F00' }"
					></gmap-circle>
				</template>
				<ground-overlay
					v-for="(overlayImage, index) in overlayImages"
					:key="'222' + index"
					:source="overlayImage.image"
					:bounds="{
						north: overlayImage.ne_latitude,
						south: overlayImage.sw_latitude,
						east: overlayImage.ne_longitude,
						west: overlayImage.sw_longitude
					}"
					:opacity="overlayImage.transparancies / 100"
				/>
				<template v-if="$store.state.showOtherPlayers">
					<gmap-marker
						v-for="(player, index) in $store.state.playersPosition"
						:position="{ lat: player.latitude == $store.state.player.position.lat ? player.latitude + 0.0015 : player.latitude, lng: player.longitude }"
						:icon="{ url: player.is_event_staff ? 'team_icon_staff.png' : 'team_icon_other.png' }"
						:label="{
							text: player.name,
							color: '#000',
							fontWeight: 'bold',
							fontSize: '12px',
							className: 'additionalCss'
						}"
						:zIndex="998"
						:key="'333' + index"
						:title="player.name"
					>
					</gmap-marker>
				</template>
			</template>
		</gmap-map>
		<div class="distance-text">{{ message }}</div>

		<!-- launch the callout-->
		<v-dialog v-model="showLaunch" fullscreen>
			<v-card
				:style="
					this.$store.state.principal.activity && this.$store.state.principal.activity.background_image
						? 'background-image: url(' + this.$store.state.principal.activity.background_image + '); background-position: center; background-repeat: no-repeat; background-size: cover;'
						: ''
				"
				tile
			>
				<v-card-title class="gtheader white--text">
					{{ checkPoint.name && checkPoint.name != "null" ? checkPoint.name : "Checkpoint" }}
					<v-spacer></v-spacer>
					<v-btn icon @click="showLaunch = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="d-flex flex-column justify-space-between align-center">
					<media v-if="checkPoint.callout_image" :name="checkPoint.callout_image" />
					<div class="subheading wrap-text pt-4">
						{{ checkPoint.callout_text }}
					</div>
				</v-card-text>
				<v-card-actions class="d-flex justify-center">
					<v-btn tile @click="showLaunch = false" color="secondary lighten-2" min-width="100">{{ $t("cancel") }}</v-btn>
					<v-btn tile @click="closeDialogLaunch()" color="primary" min-width="100">{{ $t("launch") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- launch the task-->
		<v-dialog v-model="showTask" fullscreen>
			<!-- show element in a task of checkpoint -->
			<v-card
				:style="
					this.$store.state.principal.activity && this.$store.state.principal.activity.background_image
						? 'background-image: url(' + this.$store.state.principal.activity.background_image + '); background-position: center; background-repeat: no-repeat; background-size: cover;'
						: ''
				"
				tile
			>
				<v-card-title class="gtheader white--text">{{ $t("task") }}</v-card-title>
				<!-- <v-card-title v-if="task.name && task.description">{{ task.name }}-{{ task.description }}</v-card-title> -->
				<v-progress-linear v-if="task.time_limit > 0" color="red" :value="(100 / task.time_limit) * timer" :buffer-value="100" reverse height="20">
					<span>{{ secondsToHms(timer) }}</span>
				</v-progress-linear>
				<v-card-text class="d-flex flex-column justify-space-between align-center">
					<v-form :style="this.$store.state.principal.activity && this.$store.state.principal.activity.background_image ? 'background-color: white; margin-top: 24px;' : ''" ref="taskForm">
						<v-container v-if="showTask">
							<div v-for="(element, index) in task.elements" :key="'333' + index" class="d-flex flex-column justify-space-between">
								<p v-if="element.type == 'text'" class="wrap-text" v-html="element.pivot.content"></p>

								<!-- EMBED -->
								<media v-if="element.type == 'image' || element.type == 'audio'" :name="getSourceFromContent(element.pivot.content)" />
								<iframe
									v-if="element.type == 'youtube_video'"
									frameborder="0"
									height="250"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									scrolling="0"
									:src="getUrlEmbed(element.pivot.content)"
									allowfullscreen
									class="mb-4"
								></iframe>
								<v-btn v-if="element.type == 'web_link'" class="mx-auto mb-4" :href="JSON.parse(element.pivot.content).url" target="_blank" color="primary" tile min-width="100">{{
									JSON.parse(element.pivot.content).button_text
								}}</v-btn>
								<iframe
									v-if="element.type == 'web_page'"
									class="mb-4"
									:src="element.pivot.content"
									:width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
									:height="$vuetify.breakpoint.smAndDown ? '250px' : '400px'"
									frameborder="0"
									seamless="seamless"
								></iframe>

								<v-radio-group v-if="element.type == 'multiple_choice'" v-model="element.multiple_choice" @change="setAnswer(element.type, element.pivot.id, element.customId, element.multiple_choice)" dense hide-details>
									<v-radio v-for="(content, index) in JSON.parse(element.pivot.content)" :key="'444' + index" :label="content.question" :value="content.question"></v-radio>
								</v-radio-group>
								<div v-if="element.type == 'multiple_answer'" class="mb-4">
									<div v-for="(content, index) in JSON.parse(element.pivot.content)" :key="'555' + index">
										<v-checkbox
											@change="setAnswer(element.type, element.pivot.id, element.customId, element.multiple_answer)"
											v-model="element.multiple_answer"
											:label="content.question"
											:value="content.question"
											dense
											hide-details
										>
										</v-checkbox>
									</div>
								</div>
								<v-img v-if="element.type == 'photo_answer' && photoTemp && photoTemp.startsWith('data')" :src="photoTemp" max-height="400" class="mb-4" contain></v-img>
								<input
									v-if="element.type == 'photo_answer'"
									type="file"
									id="file"
									accept="image/png, image/jpeg, image/jpg, image/gif"
									style="display: none"
									@change="showPhoto"
									:onchange="setAnswer(element.type, element.pivot.id, element.customId, attachment)"
									required
								/>
								<div v-if="element.type == 'photo_answer'" class="text-center mx-auto mb-4">
									<v-row no-gutters>
										<v-col cols="12" sm="auto" offset="0">
											<v-btn v-if="element.type == 'photo_answer'" color="primary" @click="openFileDialog()" min-width="100" tile>{{ $t("take_photo") }}</v-btn>
										</v-col>
										<v-col cols="12" sm="auto" :class="$vuetify.breakpoint.width > 600 ? 'pt-2' : ''">
											<span class="font-weight-black mx-4">OR</span>
										</v-col>
										<v-col cols="12" sm="auto">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn v-if="element.type == 'photo_answer'" color="primary" @click="pasteClipboard()" min-width="100" tile v-bind="attrs" v-on="on">{{ $t("paste_from_clipboard") }}</v-btn>
												</template>
												<span>If button is not working, please paste using ctrl + V (Windows) or Command (⌘) + V (Mac)</span>
											</v-tooltip>
										</v-col>
									</v-row>
								</div>
								<v-text-field v-if="element.type == 'text_answer'" type="text" v-model="element.text_answer" @change="setAnswer(element.type, element.pivot.id, element.customId, element.text_answer)"></v-text-field>
								<v-text-field v-if="element.type == 'number_answer'" type="number" v-model="element.number_answer" @change="setAnswer(element.type, element.pivot.id, element.customId, element.number_answer)"></v-text-field>
								<iframe v-if="element.type == 'video_answer' && videoTemp" :src="videoTemp" class="mb-4" frameborder="0" allow="autoplay = 0; accelerometer; encrypted-media; gyroscope; picture-in-picture;" scrolling="0"></iframe>
								<input
									v-if="element.type == 'video_answer'"
									type="file"
									id="fileVideo"
									accept="video/mp4"
									style="display: none"
									@change="showVideo"
									:onchange="setAnswer(element.type, element.pivot.id, element.customId, attachmentVideo)"
									required
								/>
								<v-btn v-if="element.type == 'video_answer'" class="mx-auto mb-4" color="primary" @click="openFileVideoDialog()" width="300" tile>{{ $t("choose_video") }}</v-btn>
								<iframe
									v-if="element.type == 'video_recording' && recordingVideoTemp"
									:src="recordingVideoTemp"
									class="mb-4"
									frameborder="0"
									allow="autoplay = 0; accelerometer; encrypted-media; gyroscope; picture-in-picture;"
									scrolling="0"
								></iframe>
								<input
									v-if="element.type == 'video_recording'"
									type="file"
									id="fileRecordingVideo"
									accept="video/mp4"
									style="display: none"
									@change="showRecordingVideo"
									:onchange="setAnswer(element.type, element.pivot.id, element.customId, attachmentRecordingVideo)"
									required
								/>
								<v-btn v-if="element.type == 'video_recording'" class="mx-auto mb-4" color="primary" @click="openFileRecordingVideoDialog()" width="300" tile>{{ $t("record_video") }}</v-btn>
								<v-btn v-if="element.type == 'finish'" class="mx-auto mb-4" @click="endActivity()" color="primary" tile min-width="100">{{ element.pivot.content }}</v-btn>
							</div>
							<div class="d-flex justify-center mt-4">
								<v-btn class="mx-2" v-if="$root.hasAnswer(task.elements) && !task.pass_button_disabled" @click="showDialogPass()" color="secondary lighten-2" tile min-width="100">{{ $t("pass") }}</v-btn>
								<v-btn class="mx-2" v-if="$root.hasAnswer(task.elements) && checkPoint.answer_type_id == 3" @click="showDialogPasswordPersonal()" color="primary" tile min-width="100">{{ $t("submit") }}</v-btn>
								<v-btn class="mx-2" v-else-if="$root.hasAnswer(task.elements)" @click="submit()" color="primary" tile min-width="100">{{ $t("submit") }}</v-btn>
								<v-btn class="mx-2" v-else @click="checkPoint.correctness = 100, continueSubmit(checkPoint)" color="primary" tile min-width="100">{{ $t("cont") }}</v-btn>
							</div>
						</v-container>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- Fullscreen dialog with the big compass -->
		<v-dialog v-model="isCompassDialogOpen" fullscreen hide-overlay>
			<v-card height="100%" class="d-flex flex-column">
				<v-card-title class="gtheader white--text">
					Compass
					<v-spacer></v-spacer>
					<v-btn icon @click="isCompassDialogOpen = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="d-flex justify-center align-center flex-grow-1">
					<!-- Your big compass styling here, use the compassRotationAngle for the rotation -->
					<div v-show="isSetDestination" @click="handleCompassClick">
						<v-icon size="325" class="align-self-center" :style="compassStyle">mdi-chevron-up</v-icon>
					</div>
				</v-card-text>
				<v-card-actions class="justify-center">
					<div class="distance-dialog-text">{{ message }}</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- type == password_personal -->
		<v-dialog v-model="showPasswordPersonal" max-width="500" no-click-animation persistent>
			<v-card>
				<v-card-title class="gtheader white--text justify-center">{{ $t("person_at_checkpoint") }}</v-card-title>
				<v-card-text class="d-flex justify-center mt-4">
					<v-img src="ic_app.png" max-width="150"></v-img>
				</v-card-text>
				<v-card-text>
					<v-container>
						<v-text-field type="password" v-model="passwordPersonal" label="Password Personal"></v-text-field>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="secondary lighten-2" tile @click="closeDialogPasswordPersonal()" min-width="100">{{ $t("back") }}</v-btn>
					<v-btn text color="primary" tile @click="submitPassword()" min-width="100">{{ $t("cont") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- player answer -->
		<v-dialog v-model="showPlayerAnswer" max-width="400" no-click-animation persistent>
			<v-card>
				<v-card-title class="gtheader white--text justify-center">{{ $t("player_answer") }}</v-card-title>
				<v-card-text class="d-flex justify-center mt-4">
					<v-img src="ic_app.png" max-width="150"></v-img>
				</v-card-text>
				<v-card-text>
					<v-container>
						<v-text-field v-model="playerAnswer" :label="this.$root.setValue($t('enter_score'), this.task.point)"></v-text-field>
					</v-container>
				</v-card-text>
				<v-card-actions class="justify-center">
					<v-spacer></v-spacer>
					<v-btn text color="secondary lighten-2" tile @click="closeDialogPlayerAnswer()" min-width="100">{{ $t("back") }}</v-btn>
					<v-btn text color="primary" tile @click="submit()" min-width="100">{{ $t("cont") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- type == choose route -->
		<v-dialog v-model="showChooseRoute" max-width="400" no-click-animation persistent>
			<v-card>
				<v-card-title class="gtheader white--text justify-center">{{ $t("choose_a_way") }}</v-card-title>
				<v-card-text class="d-flex justify-center mt-4">
					<v-img src="ic_app.png" max-width="150"></v-img>
				</v-card-text>
				<v-card-text>
					<v-list dense>
						<v-subheader>{{ checkPoint.instruction }}</v-subheader>
						<v-list-item-group color="primary">
							<v-list-item v-for="(route, index) in checkPoint.routes" :key="'666' + index" @click="nextRoute({}, route)">
								<v-list-item-content>
									<v-list-item-title>{{ route.name }}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-icon>
									<v-icon>mdi-chevron-right</v-icon>
								</v-list-item-icon>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- show message after submitting answer -->
		<v-dialog v-model="showMessage.show" max-width="400" persistent>
			<v-card>
				<v-card-title :class="showMessage.color ? showMessage.color : 'gtheader'" class="justify-center white--text">{{ showMessage.title }}</v-card-title>
				<v-card-text v-if="showMessage.icon" class="d-flex justify-center mt-4">
					<v-img :src="showMessage.icon" max-width="150"></v-img>
				</v-card-text>
				<v-card-actions class="d-flex flex-column justify-center text-center wrap-text" v-html="showMessage.message"></v-card-actions>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="primary" tile @click="closeDialogMessage()" min-width="100">{{ $t("cont") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- show message before pass the task -->
		<v-dialog v-model="showPass" max-width="400" persistent>
			<v-card>
				<v-card-title class="gtheader justify-center white--text">{{ $t("notification") }}</v-card-title>
				<v-card-actions class="d-flex flex-column justify-center">{{ $t("pass_task_confirm") }}</v-card-actions>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="secondary lighten-2" tile @click="nextRoute(checkPoint), closeDialogPass()" min-width="100">{{ $t("pass") }}</v-btn>
					<v-btn text color="secondary lighten-2" tile @click="closeDialogPass()" min-width="100">{{ $t("cancel") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- show confirm message -->
		<v-dialog v-model="showAlert.show" max-width="400" persistent>
			<v-card>
				<v-card-text>
					<v-row>
						<v-col class="d-flex flex-column justify-center align-center mt-4">
							<v-icon size="80" :color="showAlert.color">{{ showAlert.icon }}</v-icon>
							<div>{{ showAlert.message }}</div>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="d-flex flex-column justify-center">
					<v-btn color="primary" tile @click="showAlert.show = false" min-width="100">{{ $t("ok") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- show confirm message -->
		<v-dialog v-model="showStartArea.show" max-width="400" persistent>
			<v-card>
				<v-card-text>
					<v-row>
						<v-col class="d-flex flex-column justify-center align-center mt-4">
							<v-icon size="80" :color="showStartArea.color">{{ showStartArea.icon }}</v-icon>
							<div>{{ showStartArea.message }}</div>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="d-flex flex-column justify-center">
					<v-btn color="primary" tile @click="openNextRouteOfStartArea()" min-width="100">{{ $t("cont") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<style scoped>
.additionalCss {
	margin-top: 75px;
	padding: 5px;
	background-color: white;
	white-space: pre-wrap; /* css-3 */
	white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
	white-space: -pre-wrap; /* Opera 4-6 */
	white-space: -o-pre-wrap; /* Opera 7 */
	word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.compass-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.compass-icon svg {
  color: #333;
  font-size: 24px;
}
.distance-text {
  position: absolute;
  bottom: 20px;
  left: 10px;
  z-index: 2;
  color: red;
}
.distance-dialog-text {
  z-index: 2;
  color: red;
}

</style>

<script>
import Vue from "vue";
import media from "../media";
import axios from "axios";
import { mapGetters } from 'vuex'

import firebase from "firebase";
export default {
	name: "GoogleMap",
	components: {
		media,
	},
	computed: {
		...mapGetters(['currentLocation']),
	},
	data() {
		return {
			mapOptions: {
				streetViewControl: false,
				mapTypeControl: false,
				fullscreenControl: false,
				disableDefaultUI: true,
				styles: [
					{
						featureType: "landscape",
						elementType: "geometry",
						stylers: [
							{
								visibility: "on",
							},
							{
								color: "#e0e0e0",
							},
						],
					},
				]
			},
			zoomLevel: 10,
			targetCoordinate: null, // Example target coordinate
			rotationAngle: 0,
			compassStyle: {
				transform: 'rotate(0deg)',
				transition: 'transform 0.5s ease-out'
			},
			animationInterval: null,
			targetMarker: null, // Declare targetMarker as a data property
			targetAngle: 0, // Add targetAngle to the data object
			selectedMarker: null,
			arrowLine: null,
			currentPosition: null, // Holds the current position of the user
			isCompassDialogOpen: false,
			startAreaPolygon: null,
			isSetDestination: false,
			message: '',
			infoWindowPos: null,
			infoWinOpen: false,
			currentMidx: null,
			infoOptions: {
				content: "",
				//optional: offset infowindow so it visually sits nicely on top of our marker
				pixelOffset: {
					width: 0,
					height: -35
				}
			},
			center: {
				lat: this.$store.state.principal.activity.map_area[0].latitude + (this.$store.state.principal.activity.map_area[2].latitude - this.$store.state.principal.activity.map_area[0].latitude) / 2,
				lng: this.$store.state.principal.activity.map_area[0].longitude + (this.$store.state.principal.activity.map_area[2].longitude - this.$store.state.principal.activity.map_area[0].longitude) / 2
			},
			paths: [],
			start: [],
			forbidden: [],
			forbiddenMarker: [],
			finish: [],
			finishMarker: {},
			startMarker: {},
			overlayImages: [],
			checkPointId: null,
			dataTokens: [],
			checkPoint: {},
			checkPointList: [],
			//task purpose
			task: {},
			elementList: [],
			showLaunch: false,
			showTask: false,
			showChooseRoute: false,
			showPasswordPersonal: false,
			showPlayerAnswer: false,
			showMessage: { show: false, title: "", icon: "", message: "" },
			showAlert: { show: false, color: "", icon: "", message: "" },
			showStartArea: { show: false, color: "", icon: "", message: "" },
			showPass: false,
			messageList: [],
			timer: 0,
			timerInterval: 0,
			startTime: 0,
			endTime: 0,
			taskTime: 0,
			passwordPersonal: null,
			playerAnswer: null,
			taskAnswer: null,
			taskAnswerList: [],
			correctAnswer: 0,
			photoTemp: null,
			attachment: null,
			videoTemp: null,
			attachmentVideo: null,
			recordingVideoTemp: null,
			attachmentRecordingVideo: null,
			showHolder: null, // launch, task, message, choose_route, password_personal, player_answer
			zoom: this.$store.state.principal.activity ? this.$store.state.principal.activity.zoom : 15
		};
	},
	watch: {
		timer: function () {
			if (this.task.time_limit && this.task.time_limit > 0 && this.timer == 0) {
				this.closeDialogPass();
				this.submit();
			}
		},
		"$store.getters.tokensLength": function () {
			if (this.$store.state.principal.dataToken) {
				let currentDataTokens = this.$root.clone(this.$store.state.principal.dataToken);
				if (this.dataTokens.length != currentDataTokens.length) {
					this.dataTokens = currentDataTokens;
					this.listCheckPoint();
				}
			}
		},
		'$store.state.player.position': {
			handler(newPosition, oldPosition) {
				if (newPosition && oldPosition && (newPosition.lat !== oldPosition.lat || newPosition.lng !== oldPosition.lng)) {
					// console.log('New position:', newPosition);
					this.updateCompass();
					this.updateMapBasedOnState();
				}
			},
			immediate: true, // Trigger the watcher immediately when the component is mounted
		},
		'$store.state.isFinished': {
			handler(isFinished) {
				// Check if newPosition and oldPosition are not null
				if (isFinished) {
					this.updateMapBasedOnState();
				}
			},
			immediate: true 
		}

	},
	mounted() {
		let vueInstance = this;
		var standardizedhostname = this.$store.state.principal.activity.licensee_id;
		var dbPaused = firebase.database().ref().child("activity_status").child(standardizedhostname).child(this.$store.state.principal.activityId);
		dbPaused.on("value", function (snapshot) {
				var paused = snapshot.child('isPaused').val();
				vueInstance.$store.commit("setPaused", paused);
		});
		this.getMapArea();

		let vm = this;
		this.$refs.gmap.$mapPromise.then(() => {
			let forbiddenBounds = new window.google.maps.LatLngBounds();
			for (let [i, path] of this.$store.state.principal.activity.forbidden_area.entries()) {
				forbiddenBounds.extend(new window.google.maps.LatLng(path.latitude, path.longitude));
				if ((i + 1) % 4 == 0) {
					vm.forbiddenMarker.push(forbiddenBounds.getCenter());
					forbiddenBounds = new window.google.maps.LatLngBounds();
				}
			}
			
			let finishBounds = new window.google.maps.LatLngBounds();
			for (let path of this.$store.state.principal.activity.finish_area) {
				finishBounds.extend(new window.google.maps.LatLng(path.latitude, path.longitude));
			}
			vm.finishMarker = finishBounds.getCenter();

			let startBounds = new window.google.maps.LatLngBounds();
			for (let path of this.$store.state.principal.activity.start_area) {
				startBounds.extend(new window.google.maps.LatLng(path.latitude, path.longitude));
			}
			vm.startMarker = startBounds.getCenter();
		});

		clearInterval(this.timerInterval);
		this.timerInterval = 0;

		window.launch = function () {
			vm.infoWinOpen = false;
			vm.showDialogLaunch();
		};

		window.destination = function (latitude, longitude) {
			vm.setDestination(latitude, longitude);
		};

		// handle image paste to clipboard
		document.addEventListener("paste", async function (e) {
			if (vm.showTask) {
				e.preventDefault();
				e.stopPropagation();
				let file = e.clipboardData.items[0].getAsFile();
				if (file && file.type.startsWith("image")) {
					const fr = new FileReader();
					fr.readAsDataURL(file);
					fr.addEventListener("load", () => {
						vm.photoTemp = fr.result;
						vm.attachment = file; // this is an image file that can be sent to server...
					});
				}
			}
		});
		window.addEventListener('deviceorientation', this.handleDeviceOrientation);
		this.startTracking();
	},
	beforeDestroy() {
		// Clean up the event listener when the component is destroyed
		window.removeEventListener('deviceorientation', this.handleDeviceOrientation);
	},
	methods: {
		handleCompassClick() {
			// Update the compass when the compass is clicked
			this.isCompassDialogOpen = true;
		},
		updateCompass() {
			if (this.targetCoordinate) {
				let vm = this;
				const targetHeading = window.google.maps.geometry.spherical.computeHeading(
					vm.$store.state.player.position,
					vm.targetCoordinate,
				);
				vm.rotationAngle = targetHeading
				vm.compassStyle.transform = `rotate(${vm.rotationAngle}deg)`;
			}
		},
		checkPositionWithinStartArea() {
			if (navigator.geolocation) {
				const playerPosition = this.$store.state.player.position;
				
				const startAreaPoints = this.$store.state.principal.activity.start_area.map(
					path => new window.google.maps.LatLng(path.latitude, path.longitude)
				);	

				this.startAreaPolygon = new window.google.maps.Polygon({
					paths: startAreaPoints,
					editable: false,
					draggable: false,
					geodesic: false,
					map: this.$refs.gmap.$mapObject,
					strokeColor: null,       // Set the border color to null
					strokeOpacity: 0,
				});

				const isWithinPolygon = window.google.maps.geometry.poly.containsLocation(
					playerPosition,
					this.startAreaPolygon
				);
				
				if (this.startAreaPolygon) {
					this.startAreaPolygon.setMap(null);
				}
				if (isWithinPolygon) {
					if (!this.$store.state.isFinished) {
						this.targetCoordinate = null;
						this.$store.commit("setCompletedStartArea", true);

						this.showStartArea.message = this.$t("you_enter_start_area");
						this.showStartArea.color = "info";
						this.showStartArea.icon = "mdi-check-circle";
						this.showStartArea.show = true;
					}
					return true;
				} else {
					return false;
				}
			} else {
				console.error('Geolocation is not supported by this browser.');
				return false;
			}
		},
		checkPositionWithinRadius() {
			if (navigator.geolocation) {
				const playerPosition = this.$store.state.player.position;
				const idxWithinRadius = this.checkPointList.findIndex(
					(m) => m.type === 1 && this.calculateDistance(m.position, playerPosition) <= m.radius
				);

				if (idxWithinRadius !== -1) {
					const markerWithinRadius = this.checkPointList[idxWithinRadius];
					this.message = "";
					this.checkAutoLaunch(markerWithinRadius);
					return true;
				} else {
					// this.message = "You are outside the radius of all markers.";
					return false;
				}
			} else {
				console.error('Geolocation is not supported by this browser.');
				return false;
			}
		},
		checkAutoLaunch(marker) {
			if (marker.is_instant) {
				this.currentMidx = this.checkPointList.indexOf(marker);
				this.setSelectedCheckPoint(marker);
				this.launch();
			} else {
				this.currentMidx = this.checkPointList.indexOf(marker);
				this.setSelectedCheckPoint(marker);
				// this.toggleLaunchWindow(marker, this.currentMidx);
			}
		},
		openNextRouteOfStartArea() {
			if (this.arrowLine) {
				this.arrowLine.setMap(null);
			}

			let startCheckpoint = this.$store.state.principal.activity.check_points.find((checkpoint) => checkpoint.is_start_check_point == true);
			if (startCheckpoint) {
				if (startCheckpoint.routes.length > 0) {
					this.checkPoint = this.getCheckPoint(startCheckpoint.routes[0].checkpoint_destination_id);
					this.currentMidx = this.checkPointList.indexOf(this.checkPoint);
					this.setSelectedCheckPoint(this.checkPoint);
					this.launch();
				}
			}
			this.showStartArea.show = false;
		},
		calculateDistance(coord1, coord2) {
			const earthRadius = 6371; // Radius of the earth in kilometers

			const latDiff = this.degreesToRadians(coord2.lat - coord1.lat);
			const lngDiff = this.degreesToRadians(coord2.lng - coord1.lng);
			const a =
				Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
				Math.cos(this.degreesToRadians(coord1.lat)) *
				Math.cos(this.degreesToRadians(coord2.lat)) *
				Math.sin(lngDiff / 2) *
				Math.sin(lngDiff / 2);
			const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			const distance = earthRadius * c * 1000; // Distance in meters

			return distance;
		},
		degreesToRadians(degrees) {
			return (degrees * Math.PI) / 180;
		},
		setDestination(lat, lng) {
			// Set the targetCoordinate to the selected marker's position
			this.targetCoordinate = {lat, lng};

			// Update the compass and line arrow
			this.updateCompass();
			this.drawLineArrow();
			this.isSetDestination = true;
			this.infoWinOpen = false;
			this.message = this.getMessageDistanceToDestination();
			this.checkPositionWithinRadius();
		},
		drawLineArrow() {
			// Remove the previous line if it exists
			if (this.arrowLine) {
				this.arrowLine.setMap(null);
			}

			const lineSymbol = {
				path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
				strokeOpacity: 0.8,
				strokeColor: '#007ACC',
			};

			const line = new window.google.maps.Polyline({
				path: [this.$store.state.player.position, this.targetCoordinate],
				icons: [
					{
						icon: lineSymbol,
						offset: '100%',
					},
				],
				map: this.$refs.gmap.$mapObject,
			});

			// Store the line object to remove it later if needed
			this.arrowLine = line;
		},
		startTracking() {
			if (navigator.geolocation) {
				navigator.geolocation.watchPosition(
				(pos) => {
					const latitude = pos.coords.latitude;
					const longitude = pos.coords.longitude;

					// Store the position in $store.state.player.position
					let player = this.$root.clone(this.$store.state.player);
					let position = {latitude: latitude, lat: latitude, longitude: longitude, lng: longitude};
					player.position = position;
					this.$store.commit("setPlayer", player);
				},
				(error) => {
					console.error(error);
				}
				);
			} else {
				console.error('Geolocation is not supported by this browser.');
			}
		},
		onMapLoaded() {
			this.updateMapBasedOnState();
		},
		handleMarkerClick(marker, index) {
			if (marker.type === 1) {
				// const isWithinRadius = this.checkPositionWithinRadius();
				const indexWithinRadius = this.checkPointList.findIndex(
					(m) => m.type === 1 && this.calculateDistance(m.position, this.$store.state.player.position) <= m.radius
				);
				// console.log(indexWithinRadius, index)
				if (indexWithinRadius === index) {
					this.toggleLaunchWindow(marker, index);
				} else if (this.isSetDestination == false) {
					this.toggleSetDestinationWindow(marker, index);
				} else {
					this.toggleInfoWindow(marker, index);
				}
			} else if (marker.type === 2) {
				this.toggleLaunchWindow(marker, index);
			}
		},
		getMessageDistanceToDestination() {
			if (this.isSetDestination && this.targetCoordinate) {
				const distance = this.calculateDistance(
					this.$store.state.player.position,
					this.targetCoordinate
				);

				// Customize the message based on your requirements
				if (distance <= 0) {
					return "";
				} else {
					return `You are ${distance.toFixed(2)} meters away from the destination.`;
				}
			} else {
				return "";
			}
		},
		handleDeviceOrientation(event) {
			// Update compass rotation angle based on the device orientation
			// This depends on the device's orientation event properties, such as event.alpha, event.beta, event.gamma
			// For simplicity, let's assume event.alpha represents the compass rotation angle in degrees.
			this.rotationAngle = event.alpha;
		},
		updateMapBasedOnState() {
			// if have start area
			if (!this.$store.state.isCompletedStartArea) {
				this.checkPositionWithinStartArea();
				let startBounds = new window.google.maps.LatLngBounds();
				for (let path of this.$store.state.principal.activity.start_area) {
					startBounds.extend(new window.google.maps.LatLng(path.latitude, path.longitude));
				}
				this.targetCoordinate = startBounds.getCenter();
				this.drawLineArrow();
			} else if (!this.$store.state.isFinished) {
				this.checkPositionWithinRadius();
				if (this.isSetDestination) {
					// Update arrow destination
					this.drawLineArrow();
					// Get the distance message and update the message state
					this.message = this.getMessageDistanceToDestination();
				} else {
					if (this.arrowLine) {
						this.arrowLine.setMap(null);
					}
				}
			} else if (this.$store.state.isFinished) {
				let finishBounds = new window.google.maps.LatLngBounds();
				for (let path of this.$store.state.principal.activity.finish_area) {
					finishBounds.extend(new window.google.maps.LatLng(path.latitude, path.longitude));
				}

				let targetLat = finishBounds.getCenter().lat();
				let targetLng = finishBounds.getCenter().lng();
				if (targetLat !== 0 && targetLng !== 0) {
					// Both latitude and longitude are numeric
					this.targetCoordinate = finishBounds.getCenter();
					this.drawLineArrow();
				} else {
					this.$store.commit("setCompletedStartArea", true);
					this.isSetDestination = false;
					this.targetCoordinate = null;
					this.message = "";
					if (this.arrowLine) {
						this.arrowLine.setMap(null);
					}
				}
			}
		},

		getMapArea() {
			// set restriction
			let mapArea = this.$store.state.principal.activity.map_area;
			let bounds = {
				north:
					mapArea.reduce(function (prev, curr) {
						return prev.latitude > curr.latitude ? prev : curr;
					}).latitude + 0.0025,
				south:
					mapArea.reduce(function (prev, curr) {
						return prev.latitude < curr.latitude ? prev : curr;
					}).latitude - 0.0025,
				east:
					mapArea.reduce(function (prev, curr) {
						return prev.longitude > curr.longitude ? prev : curr;
					}).longitude + 0.006,
				west:
					mapArea.reduce(function (prev, curr) {
						return prev.longitude < curr.longitude ? prev : curr;
					}).longitude - 0.006
			};
			this.mapOptions.restriction = { latLngBounds: bounds, strictBounds: true };

			this.start = this.$store.state.principal.activity.start_area;
			for (let path of this.start) {
				path.lat = path.latitude;
				path.lng = path.longitude;
			}
			if (this.start.length > 0 && this.$store.state.isCompletedStartArea == false) {
				this.$store.commit("setCompletedStartArea", false);
			} else {
				this.$store.commit("setCompletedStartArea", true);
			}
			let forbiddenArea = this.$store.state.principal.activity.forbidden_area;
			let temp = [];
			for (let [i, path] of forbiddenArea.entries()) {
				path.lat = path.latitude;
				path.lng = path.longitude;
				temp.push(path);
				if ((i + 1) % 4 == 0) {
					this.forbidden.push(temp);
					temp = [];
				}
			}
			this.finish = this.$store.state.principal.activity.finish_area;
			for (let path of this.finish) {
				path.lat = path.latitude;
				path.lng = path.longitude;
			}
			if (this.$store.state.principal.overlayImage != null) {
				this.overlayImages = this.$store.state.principal.overlayImage;
				for (let path of this.overlayImages) {
					let position = {};
					position.lat = path.sw_latitude;
					position.lng = path.sw_longitude;
					path.position = position;
					this.zoom = path.zoom;
					this.mapOptions.minZoom = path.min_zoom;
					this.mapOptions.maxZoom = path.max_zoom;
					// this.mapOptions.restriction = { latLngBounds: { north: path.ne_latitude, south: path.sw_latitude, east: path.ne_longitude, west: path.sw_longitude }, strictBounds: true };
				}
			}

			if (!this.$store.state.isFinished) {
				this.listCheckPoint();
			} else {
				if (this.showMessage.show) {
					this.showHolder = "finish";
				} else {
					this.$root.showFinish = true;
				}
			}
		},
		listCheckPoint() {
			this.checkPointList = this.$root.clone(this.$store.state.principal.activity.check_points);

			// add variable lat and lng
			for (let path of this.checkPointList) {
				path.position.lat = path.position.latitude;
				path.position.lng = path.position.longitude;
			}

			let doneCheckpoint = JSON.stringify(this.$store.state.principal.doneCheckpoint) == "[]" ? [] : JSON.parse(this.$store.state.principal.doneCheckpoint);

			if (this.$store.state.player.position === undefined || doneCheckpoint.length == 0) {
				let startCheckpoint = this.checkPointList.find((checkpoint) => checkpoint.is_start_check_point == true);
				let player = this.$root.clone(this.$store.state.player);
				if (startCheckpoint) {
					player.position = this.$root.clone(startCheckpoint.position);
					this.center = player.position;
					this.setSelectedCheckPoint(this.getCheckPoint(startCheckpoint.id));
				} else {
					let position = {};
					position.latitude = this.center.lat;
					position.lat = this.center.lat;
					position.longitude = this.center.lng;
					position.lng = this.center.lng;
					player.position = position;
				}
				this.$store.commit("setPlayer", player);
			}

			// hide checkpoint with flag is_hidden true
			// this.checkPointList = this.checkPointList.filter((checkpoint) => checkpoint.is_hidden == false && ((checkpoint.required_keys == '[]' || checkpoint.token_type == 1) && checkpoint.source_routes.length == 0));
			this.checkPointList = this.checkPointList.filter(
				(checkpoint) =>
					checkpoint.is_hidden == false &&
					(checkpoint.required_keys == "[]" || this.checkRequiredKeys(JSON.parse(checkpoint.required_keys), checkpoint.token_type)) &&
					checkpoint.source_routes.length == 0 &&
					this.$store.state.principal.score >= checkpoint.required_point &&
					!this.$store.state.isFinished
			);

			//hide done checkpoint
			if (doneCheckpoint && doneCheckpoint.length > 0) {
				for (let id of doneCheckpoint) {
					this.checkPointList = this.checkPointList.filter((checkpoint) => checkpoint.id != id || checkpoint.is_permanent == true);
				}
			}

			if (this.checkPointList.length == 0 && !this.$store.state.isFinished && !this.$store.state.currentCheckPoint) {
				this.endActivity();
			}

			if (this.checkPoint.id == undefined && this.$store.state.currentCheckPoint != null) {
				this.setSelectedCheckPoint(this.$store.state.currentCheckPoint);
			}
		},
		checkRequiredKeys(requiredKeys, type) {
			let dataTokens = JSON.stringify(this.$store.state.principal.dataToken) == "[]" ? [] : this.$root.clone(this.$store.state.principal.dataToken);
			let normalTokens = requiredKeys.filter((x) => x.type == 0);
			let ninjaTokens = requiredKeys.filter((x) => x.type == 1);

			if (type == 0) {
				let invalidNormalToken = null;
				let invalidNinjaToken = null;

				if (normalTokens.length > 0) {
					invalidNormalToken = normalTokens.find((normal) => {
						let token = dataTokens.find((token) => token.id == normal.id);
						return token === undefined || (token != null && token.count < normal.count);
					});
				}

				if (ninjaTokens.length > 0) {
					if (normalTokens.length == 0) {
						let validNinjaToken = ninjaTokens.find((ninja) => {
							let token = dataTokens.find((token) => token.id == ninja.id);
							return token == undefined || (token != null && token.count < ninja.count);
						});

						return validNinjaToken != null;
					}

					invalidNinjaToken = ninjaTokens.find((ninja) => {
						let token = dataTokens.find((token) => token.id == ninja.id);
						return token !== undefined || (token != null && token.count >= ninja.count);
					});
				}

				return invalidNormalToken == null && invalidNinjaToken == null;
			} else {
				let validNormalToken = null;
				let invalidNinjaToken = null;

				if (normalTokens.length > 0) {
					validNormalToken = normalTokens.find((normal) => {
						let token = dataTokens.find((token) => token.id == normal.id);
						return token != null && token.count >= normal.count;
					});
				}

				if (ninjaTokens.length == 0) return validNormalToken != null;

				invalidNinjaToken = ninjaTokens.find((ninja) => {
					let token = dataTokens.find((token) => token.id == ninja.id);
					return token != null && token.count >= ninja.count;
				});

				return validNormalToken != null || invalidNinjaToken == null;
			}
		},
		checkActiveSourceRoute(sourceRoutes) {
			if (sourceRoutes.length == 0) {
				return false;
			}
			let route = sourceRoutes.find((route) => route.count > 0);
			return route != null;
		},
		getCheckPoint(id) {
			let allCheckPoints = this.$root.clone(this.$store.state.principal.activity.check_points);
			// add variable lat and lng
			for (let path of allCheckPoints) {
				path.position.lat = path.position.latitude;
				path.position.lng = path.position.longitude;
			}

			let currentCheckPoint;
			if (id !== null && id !== undefined) {
				currentCheckPoint = allCheckPoints.find((checkpoint) => checkpoint.id == id);
			} else {
				currentCheckPoint = allCheckPoints.find((checkpoint) => checkpoint.is_start_check_point);
			}

			if (currentCheckPoint) {
				// arrival point in checkpoint
				if (currentCheckPoint.arrival_point != 0 || currentCheckPoint.awarded_keys != "[]") {
					let tokenMessage = "";
					if (currentCheckPoint.show_key == true) {
						let tokens = JSON.parse(currentCheckPoint.awarded_keys);
						for (let i in tokens) {
							let token = this.$store.state.principal.keys.length > 0 ? this.$store.state.principal.keys.find((x) => x.id == tokens[i].id) : null;
							tokenMessage = tokenMessage + (token ? token.name : "") + " x " + tokens[i].count + "" + (i < tokens.length - 1 ? ", " : "");
						}
					}
					if (currentCheckPoint.arrival_point != 0 || (currentCheckPoint.awarded_keys != "[]" && currentCheckPoint.show_key == true)) {
						let title = this.$t("notification");
						let msg = "";
						if (currentCheckPoint.arrival_point != 0 && tokenMessage != "") {
							msg = this.$root.setValue(this.$t("you_score_point_key"), currentCheckPoint.arrival_point);
							msg = this.$root.setValue(msg, tokenMessage);
						} else if (currentCheckPoint.arrival_point != 0) {
							msg = this.$root.setValue(this.$t("award_point_arrival"), currentCheckPoint.arrival_point);
						} else if (tokenMessage != "") {
							msg = this.$root.setValue(this.$t("award_key_arrival"), tokenMessage);
						}
						let message = "<p class='mb-4'>" + msg + "</p>";
						this.messageList.push(
							{
								show: true,
								title: title,
								icon: null,
								message: message
							}
						);
						if (!this.showMessage.show) {
							this.showDialogMessage();
						}
						if (currentCheckPoint.task != null && currentCheckPoint.task.elements.length > 0) {
							this.showHolder = "task";
						}
					}

					this.$store.commit("setScore", currentCheckPoint.arrival_point);
					this.$store.commit("setScoreHistory", { id: Date.now(), type: "checkpoint", data: currentCheckPoint.id, score: currentCheckPoint.arrival_point, activityDbId: currentCheckPoint.id });

					// get awarded keys (token) if exist
					let awardedKeys = JSON.parse(currentCheckPoint.awarded_keys);
					if (awardedKeys.length > 0) {
						for (let i in awardedKeys) {
							this.$store.commit("setToken", awardedKeys[i]);
						}
					}
				}

				if ((currentCheckPoint.route_type_id == 1 && currentCheckPoint.task == null) || (currentCheckPoint.route_type_id == 2 && currentCheckPoint.task == null && currentCheckPoint.routes.length == 1)) {
					if (currentCheckPoint.routes.length > 0) {
						this.$store.commit("doneCheckPoint", currentCheckPoint.id);
						currentCheckPoint = this.getCheckPoint(currentCheckPoint.routes[0].checkpoint_destination_id);
					}
				}
			}
			return currentCheckPoint;
		},
		//curent checkpoint
		setSelectedCheckPoint(checkPoint) {
			// reset counter correct answer
			this.correctAnswer = 0;

			if (!this.infoWinOpen) {
				if (checkPoint.task != null) {
					this.$store.commit("setCurrentCheckPoint", checkPoint);
				} else {
					this.$store.commit("setCurrentCheckPoint", null);
				}
			}

			if (checkPoint) {
				this.checkPointId = checkPoint.id;
				this.checkPoint = checkPoint;
				// reset initiate answer
				this.taskAnswerList = [];
				// set initiate current attempts
				this.checkPoint.current_attempts = 0;

				if (this.checkPoint.task != null) {
					this.task = this.checkPoint.task;
					if (this.task.elements) {
						this.elementList = this.task.elements;

						// set initiate answer
						for (let i in this.elementList) {
							this.elementList[i].customId = i;
							if (this.$root.isAnswerElement(this.elementList[i].type)) {
								this.setAnswer(this.elementList[i].type, this.elementList[i].pivot.id, this.elementList[i].customId, null);
								if (this.elementList[i].type == "multiple_answer") {
									Vue.set(this.elementList[i], "multiple_answer", []);
								}
							}
						}
					}
				} else {
					this.task = {};
					this.elementList = [];
				}

				if (this.checkPoint.task != null && this.checkPoint.task.elements.length > 0 && this.showHolder != "task" && !this.infoWinOpen) {
					if (this.checkPoint.is_instant) {
						this.showDialogTask();
					}
				} else if (this.checkPoint.route_type_id == 2 && this.checkPoint.routes && this.checkPoint.routes.length > 0 && !this.infoWinOpen) {
					this.showDialogChooseRoute();
				}
			}
		},
		nextRoute(checkpoint, route) {
			this.closeDialogChooseRoute();
			this.closeDialogTask();
			this.$store.commit("doneCheckPoint", this.checkPoint.id);
			// decrease token
			// if (route == null) {
			// 	this.$store.commit("decreaseToken", JSON.parse(checkpoint.required_keys));
			// }

			if (checkpoint.route_type_id == 2 && checkpoint.routes.length > 1) {
				if (this.showMessage.show) {
					this.showHolder = "choose_route";
				} else {
					this.showDialogChooseRoute();
				}
				
			} else {
				if (route == null && checkpoint.routes.length > 0) {
					if (checkpoint.route_type_id == 1) {
						route = checkpoint.routes[this.$root.randomInt(checkpoint.routes.length)];
					} else {
						let taskCorrectness = 0;
						if (checkpoint.task.point == 0) {
							taskCorrectness = 100;
						} else {
							taskCorrectness = checkpoint.correctness * 100 / checkpoint.task.point;
						}
						let vm = this;
						let availableRoutes = checkpoint.routes.filter((route) => {
							let allCheckPoints = this.$root.clone(this.$store.state.principal.activity.check_points);
							let destination = allCheckPoints.find((x) => x.id == route.checkpoint_destination_id);
							return taskCorrectness >= route.correctness 
								&& (destination.required_keys == "[]" || vm.checkRequiredKeys(JSON.parse(destination.required_keys), destination.token_type));
						});
						if (this.correctAnswer > 0) {
							for (let r of availableRoutes) {
								if (route == null ) {
									route = r;
								} else if (r.correctness > route.correctness) {
									route = r;
								}
							}
						} else {
							// default is random
							route = availableRoutes[this.$root.randomInt(availableRoutes.length)];
						}

						if (route == null || route === undefined) {
							let title = this.$t("notification");
							let message = this.$t("no_available_route");
							this.messageList.push(
								{
									show: true,
									title: title,
									icon: null,
									message: message
								}
							);
							this.$store.commit("setCurrentCheckPoint", null);
							this.checkPoint = {};
							this.task = {};
							this.elementList = [];
							this.startTime = 0;
							this.endTime = 0;
							this.taskTime = 0;
						}
					}
				}
				if (route) {
					// point and keys (token)
					if (route.required_point != 0 || route.required_keys != "[]") {
						let title = this.$t("notification");
						let tokenMessage = "";
						if (this.checkPoint.show_key == true) {
							let tokens = JSON.parse(route.required_keys);
							for (let i in tokens) {
								let token = this.$store.state.principal.keys.length > 0 ? this.$store.state.principal.keys.find((x) => x.id == tokens[i].id) : null;
								tokenMessage = tokenMessage + (token ? token.name : "") + " x " + tokens[i].count + "" + (i < tokens.length - 1 ? ", " : "");
							}
						}
						if (route.required_point != 0 || (route.required_keys != "[]" && this.checkPoint.show_key == true)) {
							let msg = "";
							if (route.required_point != 0 && tokenMessage != "") {
								msg = this.$root.setValue(this.$t("award_point_key_route"), route.required_point);
								msg = this.$root.setValue(msg, tokenMessage);
							} else if (route.required_point != 0) {
								msg = this.$root.setValue(this.$t("award_point_route"), route.required_point);
							} else if (tokenMessage != "") {
								msg = this.$root.setValue(this.$t("award_key_route"), tokenMessage);
							}
							let message = "<p class='mb-4'>" + msg + "</p>";
							this.messageList.push(
								{
									show: true,
									title: title,
									icon: null,
									message: message
								}
							);
							if (!this.showMessage.show) {
								this.showDialogMessage();
							}
							this.showHolder = "task";
						}

						this.$store.commit("setScore", route.required_point);
						this.$store.commit("setScoreHistory", { id: Date.now(), type: "route", data: this.checkPoint.id, score: route.required_point, activityDbId: this.checkPoint.id });

						// get awarded keys (token) if exist
						let awardedKeys = JSON.parse(route.required_keys);
						if (awardedKeys.length > 0) {
							for (let i in awardedKeys) {
								this.$store.commit("setToken", awardedKeys[i]);
							}
						}
					}

					let checkPointDestination = this.getCheckPoint(route.checkpoint_destination_id);
					let doneCheckpoint = JSON.stringify(this.$store.state.principal.doneCheckpoint) == "[]" ? [] : JSON.parse(this.$store.state.principal.doneCheckpoint);
					if (doneCheckpoint.find((x) => checkPointDestination.id == x) && !checkPointDestination.is_permanent) {
						let title = this.$t("notification");
						let message = this.$t("no_available_route");
						this.messageList.push(
							{
								show: true,
								title: title,
								icon: null,
								message: message
							}
						);
						this.$store.commit("setCurrentCheckPoint", null);
						this.checkPoint = {};
						this.task = {};
						this.elementList = [];
						this.startTime = 0;
						this.endTime = 0;
						this.taskTime = 0;
					} else {
						this.setSelectedCheckPoint(checkPointDestination);
					}

					//Update player position
					let player = this.$root.clone(this.$store.state.player);
					player.position = this.$root.clone(checkPointDestination.position);
					this.$store.commit("setPlayer", player);

					// if (this.checkPoint.task != null && this.checkPoint.task.elements.length > 0 && this.showHolder != "task") {
					// 	this.showDialogTask();
					// }
				} else {
					this.$store.commit("setCurrentCheckPoint", null);
					this.checkPoint = {};
					this.task = {};
					this.elementList = [];
					this.startTime = 0;
					this.endTime = 0;
					this.taskTime = 0;
				}
			}

			this.listCheckPoint();
		},
		launch() {
			this.showDialogLaunch();
			if (this.checkPointList.length > 0) {
				if (this.checkPoint.task != null && this.checkPoint.task.elements.length > 0) {
					this.closeDialogLaunch();
					this.showDialogTask();
				}
			}
		},
		setAnswer(type, elementId, customId, answer) {
			let taskAnswer = {
				id: customId,
				elementId: elementId,
				type: type,
				answer: answer
			};

			let index = this.taskAnswerList.findIndex((x) => x.id === customId);
			if (this.taskAnswerList.length > 0 && index != -1) {
				this.taskAnswerList[index].answer = answer;
			} else {
				this.taskAnswerList.push(taskAnswer);
			}
		},
		submit() {
			let isConfirm = true;
			if (this.taskAnswerList.filter((x) => this.$root.hasFileAnswer(x.type) && x.answer == null).length > 0) {
				if (this.taskAnswerList.find((x) => x.type == "photo_answer" && x.answer == null)) {
					isConfirm = confirm(this.$t("warning_no_photo"));
				} else {
					isConfirm = confirm(this.$t("warning_no_video"));
				}
			}

			if (isConfirm) {
				this.endTime = Date.now();

				// stop countdown time if exist
				this.checkPoint.current_attempts += 1;
				let point = 0;
				let totalScore = this.$store.state.principal.score;
				this.correctAnswer = 0;

				if (this.checkPoint.answer_type_id == 3) {
					if (this.playerAnswer >= 0 && this.playerAnswer <= this.task.point) {
						point = this.playerAnswer;
						this.$store.commit("setScore", this.playerAnswer);
						let type = "answer";
						this.$store.commit("setScoreHistory", { id: Date.now(), type: type, data: this.checkPoint.id, score: this.playerAnswer, activityDbId: this.checkPoint.id });
						this.$store.commit("doneCheckPoint", this.checkPoint.id);
						this.closeDialogPlayerAnswer();
						this.closeDialogPasswordPersonal();
						this.closeDialogTask();
					} else {
						isConfirm = false;
						this.showAlert.message = this.$t("invalid_score");
						this.showAlert.color = "error";
						this.showAlert.icon = "mdi-close-circle-outline";
						this.showAlert.show = true;
					}
				} else if (this.checkPoint.answer_type_id == 2) {
					// need to be reviewed by the controller
					let title = this.$t("notification");
					let icon = this.$store.state.principal.activity.image;
					let message = this.$t("submit_answer_alert");
					this.messageList.push(
						{
							show: true,
							title: title,
							icon: icon,
							message: message
						}
					);
					if (this.checkPoint.task != null && this.checkPoint.task.elements.length > 0) {
						this.showHolder = "task";
					}
					this.showTask = false;
					if (!this.showMessage.show) {
						this.showDialogMessage();
					}
				} else {
					for (let i in this.taskAnswerList) {
						if (this.$root.isAnswerElement(this.taskAnswerList[i].type)) {
							let task = this.elementList.find((x) => x.customId == this.taskAnswerList[i].id);
							if (task != null) {
								let answer = task.pivot.content.startsWith("{") || task.pivot.content.startsWith("[") ? JSON.parse(task.pivot.content) : task.pivot.content;
								if (task.type === "text_answer") {
									var patt = new RegExp(answer ? answer.toLowerCase() : answer);
									if (patt.test(this.taskAnswerList[i].answer ? this.taskAnswerList[i].answer.toLowerCase() : this.taskAnswerList[i].answer) || answer == "") {
										point += this.applyTimerDecreaseStep(this.task.point);
										this.correctAnswer += 1;
									}
								} else if (task.type === "number_answer") {
									if (answer.is_range == 0) {
										if (this.taskAnswerList[i].answer == answer.content) {
											point += this.applyTimerDecreaseStep(this.task.point);
											this.correctAnswer += 1;
										}
									} else {
										if (this.taskAnswerList[i].answer >= answer.min && this.taskAnswerList[i].answer <= answer.max) {
											point += this.applyTimerDecreaseStep(this.task.point);
											this.correctAnswer += 1;
										}
									}
								} else if (task.type === "photo_answer") {
									if (this.taskAnswerList[i].answer != null) {
										point += this.applyTimerDecreaseStep(this.task.point);
										this.correctAnswer += 1;
									}
								} else if (task.type === "video_answer") {
									if (this.taskAnswerList[i].answer != null) {
										point += this.applyTimerDecreaseStep(this.task.point);
										this.correctAnswer += 1;
									}
								} else if (task.type === "multiple_answer") {
									if (this.taskAnswerList[i].answer && this.taskAnswerList[i].answer.length > 0) {
										let totalCorrect = answer.filter((x) => x.is_answer);
										let correct = 0;
										for (let j = 0; j < this.taskAnswerList[i].answer.length; j++) {
											if (answer.find((x) => x.question == this.taskAnswerList[i].answer[j] && x.is_answer)) {
												this.correctAnswer += 1;
												correct += 1;
											}
										}

										point += this.applyTimerDecreaseStep(Math.round(this.task.point * (correct / totalCorrect.length - (this.taskAnswerList[i].answer.length - correct) / answer.length)));
									}
								} else if (task.type === "multiple_choice") {
									if (answer.find((x) => x.question == this.taskAnswerList[i].answer && x.is_answer)) {
										point += this.applyTimerDecreaseStep(this.task.point);
										this.correctAnswer += 1;
									}
								} else if (task.type === "number_range") {
									let start = answer.content.split(",")[0];
									let end = answer.content.split(",")[1];
									if (start <= this.taskAnswerList[i].answer && this.taskAnswerList[i].answer <= end) {
										point += this.applyTimerDecreaseStep(this.task.point);
										this.correctAnswer += 1;
									}
								} else if (task.type === "video_recording") {
									if (this.taskAnswerList[i].answer != null) {
										point += this.applyTimerDecreaseStep(this.task.point);
										this.correctAnswer += 1;
									}
								}
							}
						}
					}

					if (this.correctAnswer > 0 && ((this.task.time_limit > 0 && this.timer > 0) || this.task.time_limit == 0 || this.task.time_limit == null)) {
						let totalTask = this.elementList.filter((element) => this.$root.isAnswerElement(element.type)).length;
						point = Math.round(point / totalTask);

						// ATTEMPT REDUCTION POINT
						if (this.checkPoint.attempt_reduction == 1) {
							if (this.checkPoint.current_attempts > 10) {
								point = 0;
							} else {
								let reduction = 1 / this.checkPoint.current_attempts;
								point = Math.round(point * reduction);
							}
						}

						if (point == this.task.point) {
							let title = this.$t("correct_answer");
							let icon = this.task.image_correct_answer ? this.task.image_correct_answer.rand_name : "feedback_ok.png";
							let color = "success";
							let message =
								"<p class='mb-4'>" + (this.task.feedback_correct_answer ? this.task.feedback_correct_answer : this.$t("correct_feedback")) + "</p><p>" + this.$root.setValue(this.$t("you_score_point"), point) + "</p>";
							this.messageList.push(
								{
									show: true,
									title: title,
									icon: icon,
									color: color,
									message: message
								}
							);
						} else {
							let title = this.$t("partial_correct_answer");
							let icon = this.task.image_partial_answer ? this.task.image_partial_answer.rand_name : "feedback_neutral.png";
							let color = "success";
							let message =
								"<p class='mb-4'>" + (this.task.feedback_partial_answer ? this.task.feedback_partial_answer : this.$t("partial_correct_feedback")) + "</p><p>" + this.$root.setValue(this.$t("you_score_point"), point) + "</p>";
							this.messageList.push(
								{
									show: true,
									title: title,
									icon: icon,
									color: color,
									message: message
								}
							);
						}

						if (this.$store.state.principal.activity.hide_all_answer_feedback) {
							// play correct answer sound
							this.$root.correctAnswerSound();
						}

						this.$store.commit("setScore", point);
						let type = "answer";
						this.$store.commit("setScoreHistory", { id: Date.now(), type: type, data: this.checkPoint.id, score: point, activityDbId: this.checkPoint.id });
					} else {
						if (this.$store.state.principal.activity.hide_all_answer_feedback) {
							// play wrong answer sound
							this.$root.wrongAnswerSound();
						}

						if (this.checkPoint.current_attempts < this.checkPoint.max_attempts) {
							let title = this.$t("notification");
							let msg = "";
							if (this.checkPoint.current_attempts < this.checkPoint.max_attempts) {
								msg = this.$root.setValue(this.$t("max_attempts_try_other"), (this.checkPoint.max_attempts-this.checkPoint.current_attempts));
							} else {
								msg = this.$root.setValue(this.$t("max_attempts_try_one"), (this.checkPoint.max_attempts-this.checkPoint.current_attempts));
							}
							let message = "<p class='mb-4'>" + msg + "</p>";
							this.messageList.push(
								{
									show: true,
									title: title,
									icon: null,
									color: null,
									message: message
								}
							);
							this.checkPoint.timer = undefined;
						} else {
							let title = this.$t("incorrect_answer");
							let icon = this.task.image_wrong_answer ? this.task.image_wrong_answer.rand_name : "feedback_wrong.png";
							let color = "error";
							let message = "<p class='mb-4'>" + (this.task.feedback_wrong_answer ? this.task.feedback_wrong_answer : this.$t("feedback_wrong")) + "</p>";
							this.messageList.push(
								{
									show: true,
									title: title,
									icon: icon,
									color: color,
									message: message
								}
							);
						}
					}

					// set correctness for routing correctness
					this.checkPoint.correctness = point;

					if (this.checkPoint.task != null && this.checkPoint.task.elements.length > 0) {
						this.showHolder = "task";
					}
					this.closeDialogTask();
					if (this.$store.state.principal.activity.hide_all_answer_feedback || (this.correctAnswer <= 0 && this.checkPoint.current_attempts < this.checkPoint.max_attempts)) {
						if (!this.showMessage.show) {
							this.showDialogMessage();
						}
					} else {
						this.closeDialogMessage();
					}
				}

				if (isConfirm) {
					this.closeDialogTask();
					if (this.checkPoint.current_attempts >= this.checkPoint.max_attempts || this.correctAnswer > 0) {
						let formData = new FormData();
						formData.append("player_id", this.$store.state.principal.player_id);
						formData.append("activity_id", this.checkPoint.activity_id);
						formData.append("checkpoint_id", this.checkPoint.id);
						formData.append("score", point);
						formData.append("total_score", totalScore);
						formData.append("start_time", this.startTime);
						formData.append("time", this.taskTime);
						formData.append("playing_time", this.$store.state.timer.h * 3600 + this.$store.state.timer.m * 60 + this.$store.state.timer.s);
						formData.append("latitude", this.checkPoint.position ? this.checkPoint.position.latitude : "");
						formData.append("longitude", this.checkPoint.position ? this.checkPoint.position.longitude : "");

						for (let i in this.taskAnswerList) {
							if (this.taskAnswerList[i].type == "multiple_answer") {
								formData.append(this.taskAnswerList[i].elementId, JSON.stringify(this.taskAnswerList[i].answer));
							} else {
								formData.append(this.taskAnswerList[i].elementId, this.taskAnswerList[i].answer);
							}
						}

						this.submitAnswer(formData);

						// reset variable answer holder
						this.taskAnswerList = [];

						if (this.checkPoint.route_type_id != 3 || (this.checkPoint.route_type_id == 3 && this.correctAnswer > 0)) {
							this.$store.commit("doneCheckPoint", this.checkPoint.id);
						}
						if (this.checkPoint.routes.length > 0) {
							this.nextRoute(this.checkPoint);
						} else {
							this.$store.commit("setCurrentCheckPoint", null);
							this.checkPoint = {};
							this.task = {};
							this.elementList = [];
							this.startTime = 0;
							this.endTime = 0;
							this.taskTime = 0;
							this.listCheckPoint();
						}
					}
				}
			}
		},
		continueSubmit(cp){
			//add decrease token when destination task is null and using route.
			if (cp.routes.length > 0) {
				var nextCp = this.getCheckPoint(cp.routes[0].checkpoint_destination_id);
				if(nextCp.task == null){
					if (nextCp.required_keys !== undefined && JSON.parse(nextCp.required_keys).length > 0) {
						this.$store.commit("decreaseToken", JSON.parse(nextCp.required_keys));
					}
				}
				this.nextRoute(cp);
			} else{
				this.nextRoute(cp)
			}
		},
		endActivity() {
			this.$store.commit("setFinished", true);
			this.closeDialogTask();

			if (this.showMessage.show) {
				this.showHolder = "finish";
			} else {
				this.$root.showFinish = true;
			}

			this.listCheckPoint();

			let formData = new FormData();
			formData.append("player_id", this.$store.state.principal.player_id);
			formData.append("playing_time", this.$store.state.timer.h * 3600 + this.$store.state.timer.m * 60 + this.$store.state.timer.s);
			formData.append("score", this.$store.state.principal.score);
			axios.post("/api/v1/end_activity", formData);
		},
		submitAnswer(formData) {
			// pause time scheduler
			this.$root.pause = true;

			axios
				.post("/api/v2/submit_answer", formData, { headers: { "Content-Type": "multipart/form-data" } })
				.then(() => {
					// reset task form
					if (this.showTask && this.$refs.taskForm) {
						this.$refs.taskForm.reset();
					}

					this.startTime = 0;
					this.endTime = 0;
					this.taskTime = 0;

					// run time scheduler
					this.$root.pause = false;
					this.isSetDestination = false;
					this.message = "";
				})
				.catch(() => {
					// reset task form
					if (this.showTask && this.$refs.taskForm) {
						this.$refs.taskForm.reset();
					}

					// run time scheduler
					this.$root.pause = false;
				});
		},
		getUrlEmbed(url) {
			if (url.includes("youtu")) {
				let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
				let match = url.match(regExp);
				let urlEmbed = "https://www.youtube.com/embed/" + match[2] + "?autoplay=1&showinfo=0&controls=1";
				return urlEmbed;
			}
			return url;
		},
		getSourceFromContent(content) {
			let result = content != "" ? JSON.parse(content) : content;
			return result.rand_name ? result.rand_name : result.real_name;
		},
		openFileDialog() {
			if (document.getElementById("file")) {
				document.getElementById("file").click();
			}
		},
		openFileVideoDialog() {
			if (document.getElementById("fileVideo")) {
				document.getElementById("fileVideo").click();
			}
		},
		openFileRecordingVideoDialog() {
			if (document.getElementById("fileRecordingVideo")) {
				document.getElementById("fileRecordingVideo").click();
			}
		},
		showPhoto(e) {
			const files = e.target.files;
			if (files[0] !== undefined) {
				let imageName = files[0].name;
				if (imageName.lastIndexOf(".") <= 0) {
					return;
				}
				const fr = new FileReader();
				fr.readAsDataURL(files[0]);
				fr.addEventListener("load", () => {
					this.photoTemp = fr.result;
					this.attachment = files[0]; // this is an image file that can be sent to server...
				});
			} else {
				this.attachment = null;
			}
		},
		showVideo(e) {
			const files = e.target.files;
			if (files[0] !== undefined) {
				let videoName = files[0].name;
				if (videoName.lastIndexOf(".") <= 0) {
					return;
				}
				const fr = new FileReader();
				fr.readAsDataURL(files[0]);
				fr.addEventListener("load", () => {
					this.videoTemp = URL.createObjectURL(files[0]);
					this.attachmentVideo = files[0]; // this is an image file that can be sent to server...
				});
			} else {
				this.attachmentVideo = null;
			}
		},
		showRecordingVideo(e) {
			const files = e.target.files;
			if (files[0] !== undefined) {
				let recordingVideoName = files[0].name;
				if (recordingVideoName.lastIndexOf(".") <= 0) {
					return;
				}
				const fr = new FileReader();
				fr.readAsDataURL(files[0]);
				fr.addEventListener("load", () => {
					this.recordingVideoTemp = URL.createObjectURL(files[0]);
					this.attachmentRecordingVideo = files[0]; // this is an image file that can be sent to server...
				});
			} else {
				this.attachmentRecordingVideo = null;
			}
		},
		toggleInfo(marker, idx, txtContent) {
			this.infoWindowPos = marker;
			this.infoOptions.content = '<div id="content" style="max-width: 250px;">' + '<div id="bodyContent">' + '<span style="font-weight: bold;">' + txtContent;
			"</span>" + "</div>" + "</div>";

			//check if its the same marker that was selected if yes toggle
			if (this.currentMidx == idx) {
				this.infoWinOpen = !this.infoWinOpen;
			}

			//if different marker set infowindow to open and reset current marker index
			else {
				this.infoWinOpen = true;
				this.currentMidx = idx;
			}
		},
		toggleInfoWindow(marker, idx) {
			this.infoWindowPos = marker.position;
			this.infoOptions.content =
				'<div id="content" style="max-width: 250px;">' +
				'<h3 id="firstHeading">' +
				(marker.name && marker.name != "null" ? marker.name : "Checkpoint") +
				"</h3>" +
				'<div id="bodyContent">' +
				"<p>" +
				(marker.callout_text && marker.callout_text != "null" ? marker.callout_text : "") +
				"</p>" 
				;
				
			"</div>" + "</div>";

			//check if its the same marker that was selected if yes toggle
			if (this.currentMidx == idx) {
				this.infoWinOpen = !this.infoWinOpen;
			}

			//if different marker set infowindow to open and reset current marker index
			else {
				this.infoWinOpen = true;
				this.currentMidx = idx;
				this.setSelectedCheckPoint(marker);
			}
		},
		toggleLaunchWindow(marker, idx) {
			this.infoWindowPos = marker.position;
			this.infoOptions.content =
				'<div id="content" style="max-width: 250px;">' +
				'<h3 id="firstHeading">' +
				(marker.name && marker.name != "null" ? marker.name : "Checkpoint") +
				"</h3>" +
				'<div id="bodyContent">' +
				"<p>" +
				(marker.callout_text && marker.callout_text != "null" ? marker.callout_text : "") +
				"</p>" +
				'<button style="color: #007ACC;" onclick="launch()">' +
				this.$t("launch") +
				" ></button>";
				
			"</div>" + "</div>";

			//check if its the same marker that was selected if yes toggle
			if (this.currentMidx == idx) {
				this.infoWinOpen = !this.infoWinOpen;
			}

			//if different marker set infowindow to open and reset current marker index
			else {
				this.infoWinOpen = true;
				this.currentMidx = idx;
				this.setSelectedCheckPoint(marker);
			}
		},
		toggleSetDestinationWindow(marker, idx) {
			this.infoWindowPos = marker.position;
			this.infoOptions.content =
				'<div id="content" style="max-width: 250px;">' +
				'<h3 id="firstHeading">' +
				(marker.name && marker.name != "null" ? marker.name : "Checkpoint") +
				"</h3>" +
				'<div id="bodyContent">' +
				"<p>" +
				(marker.callout_text && marker.callout_text != "null" ? marker.callout_text : "") +
				"</p>" +
				`<button style="color: #007ACC;" onclick="destination(${marker.latitude}, ${marker.longitude})">` +
				this.$t("Set Destination") +
				" ></button>";
			"</div>" + "</div>";

			//check if its the same marker that was selected if yes toggle
			if (this.currentMidx == idx) {
				this.infoWinOpen = !this.infoWinOpen;
			}

			//if different marker set infowindow to open and reset current marker index
			else {
				this.infoWinOpen = true;
				this.currentMidx = idx;
				this.setSelectedCheckPoint(marker);
			}

		},
		submitPassword() {
			if (this.passwordPersonal == this.$store.state.principal.activity.task_password) {
				this.showDialogPlayerAnswer();
			} else {
				this.showAlert.message = this.$t("incorrect_password");
				this.showAlert.color = "error";
				this.showAlert.icon = "mdi-close-circle-outline";
				this.showAlert.show = true;
			}
		},
		showDialogLaunch() {
			this.showLaunch = true;
			this.currentMidx = null;
		},
		closeDialogLaunch() {
			this.showLaunch = false;
			var showTask = true;
			// arrival point and/or awarded keys in checkpoint
			if ((this.checkPoint.arrival_point && this.checkPoint.arrival_point != 0) || (this.checkPoint.awarded_keys && this.checkPoint.awarded_keys != "[]")) {
				let title = this.$t("notification");
				let tokenMessage = "";
				if (this.checkPoint.show_key == true) {
					let tokens = JSON.parse(this.checkPoint.awarded_keys);
					for (let i in tokens) {
						let token = this.$store.state.principal.keys.length > 0 ? this.$store.state.principal.keys.find((x) => x.id == tokens[i].id) : null;
						tokenMessage = tokenMessage + (token ? token.name : "") + " x " + tokens[i].count + "" + (i < tokens.length - 1 ? ", " : "");
					}
				}

				if (this.checkPoint.arrival_point != 0 || (this.checkPoint.awarded_keys != "[]" && this.checkPoint.show_key == true)) {
					let msg = "";
					if (this.checkPoint.arrival_point != 0 && tokenMessage != "") {
						msg = this.$root.setValue(this.$t("you_score_point_key"), this.checkPoint.arrival_point);
						msg = this.$root.setValue(msg, tokenMessage);
					} else if (this.checkPoint.arrival_point != 0) {
						msg = this.$root.setValue(this.$t("award_point_arrival"), this.checkPoint.arrival_point);
					} else if (tokenMessage != "") {
						msg = this.$root.setValue(this.$t("award_key_arrival"), tokenMessage);
					}
					let message = "<p class='mb-4'>" + msg + "</p>";
					this.messageList.push(
						{
							show: true,
							title: title,
							icon: null,
							message: message
						}
					);
					if (!this.showMessage.show) {
						showTask = false;
						this.showDialogMessage();
					}
					if (this.checkPoint.task != null && this.checkPoint.task.elements.length > 0) {
						this.showHolder = "task";
					}
				}

				this.$store.commit("setScore", this.checkPoint.arrival_point);
				this.$store.commit("setScoreHistory", { id: Date.now(), type: "checkpoint", data: this.checkPoint.id, score: this.checkPoint.arrival_point, activityDbId: this.checkPoint.id });

				// get awarded keys (token) if exist
				let awardedKeys = JSON.parse(this.checkPoint.awarded_keys);
				if (awardedKeys.length > 0) {
					for (let i in awardedKeys) {
						this.$store.commit("setToken", awardedKeys[i]);
					}
				}
			}
			if(showTask){
				this.showDialogTask();
			}
		},
		showDialogTask() {
			// decrease token
			if (this.checkPoint.required_keys !== undefined && JSON.parse(this.checkPoint.required_keys).length > 0) {
				this.$store.commit("decreaseToken", JSON.parse(this.checkPoint.required_keys));
			}

			// set current checkpoint
			if (this.checkPoint.task != null) {
				this.$store.commit("setCurrentCheckPoint", this.checkPoint);
			} else {
				this.$store.commit("setCurrentCheckPoint", null);
			}

			// set position player
			let player = this.$root.clone(this.$store.state.player);
			if (this.checkPoint.position) {
				player.position = this.$root.clone(this.checkPoint.position);
			}
			this.$store.commit("setPlayer", player);

			this.timer = this.checkPoint.timer ? this.checkPoint.timer : this.task.time_limit;
			if (this.checkPoint.task != null && this.checkPoint.task.elements.length > 0 && this.task.time_limit > 0 && this.timerInterval == 0) {
				this.timerInterval = setInterval(() => {
					this.taskTime += 1;
					if (this.timer == 0) {
						clearInterval(this.timerInterval);
						this.timerInterval == 0;
					} else {
						this.timer += -1;
						// set current checkpoint with decrease timer task
						this.checkPoint.timer = this.timer;
						this.$store.commit("setCurrentCheckPoint", this.checkPoint);
					}
				}, 1000);
			}

			// set start time
			this.startTime = Date.now();

			if (this.checkPoint.task != null && this.checkPoint.task.elements.length > 0 && this.showHolder == null) {
				this.showTask = true;
			} else if (this.checkPoint.task == null && this.showHolder != "task") {
				this.$store.commit("doneCheckPoint", this.checkPoint.id);
				if (this.checkPoint.route_type_id == 1 && this.checkPoint.routes.length > 0) {
					this.nextRoute(this.checkPoint);
				} else if (this.checkPoint.route_type_id == 2 && this.checkPoint.routes.length > 0) {
					this.nextRoute(this.checkPoint);
				} else {
					this.listCheckPoint();
				}
			}
		},
		closeDialogTask() {
			// clear interval task timer if exist
			clearInterval(this.timerInterval);
			this.timerInterval = 0;

			this.photoTemp = null;
			this.attachment = null;
			this.videoTemp = null;
			this.attachmentVideo = null;
			this.recordingVideoTemp = null;
			this.attachmentRecordingVideo = null;
			this.showTask = false;
		},
		showDialogPass() {
			this.showPass = true;
		},
		closeDialogPass() {
			this.showPass = false;
		},
		showDialogChooseRoute() {
			this.showChooseRoute = true;
		},
		closeDialogChooseRoute() {
			this.showChooseRoute = false;
		},
		showDialogPasswordPersonal() {
			this.passwordPersonal = null;
			this.showPasswordPersonal = true;
		},
		closeDialogPasswordPersonal() {
			this.showPasswordPersonal = false;
			this.showDialogHolder();
		},
		showDialogPlayerAnswer() {
			this.playerAnswer = null;
			this.showPlayerAnswer = true;
		},
		closeDialogPlayerAnswer() {
			this.showPlayerAnswer = false;
			this.showDialogHolder();
		},
		showDialogMessage() {
			if (this.messageList.length > 0) {
				this.showMessage = this.messageList[0];
				this.messageList.shift();
			}
		},
		closeDialogMessage() {
			if (this.messageList.length > 0) {
				this.showDialogMessage();
			} else {
				this.showMessage = { show: false, title: "", icon: "", message: "" };

				if (this.checkPoint.route_type_id == 1 && this.checkPoint.routes.length > 0 && this.showHolder == null) {
					this.setSelectedCheckPoint(this.getCheckPoint(this.checkPoint.routes[0].checkpoint_destination_id));

					this.showDialogTask();
				}

				this.showDialogHolder();
			}
		},
		showDialogHolder() {
			if (this.showHolder == "launch") {
				this.showHolder = null;
				this.showDialogLaunch();
			} else if (this.showHolder == "task") {
				this.showHolder = null;
				this.showDialogTask();
			} else if (this.showHolder == "message") {
				this.showHolder = null;
				this.showDialogMessage();
			} else if (this.showHolder == "choose_route") {
				this.showHolder = null;
				this.showDialogChooseRoute();
			} else if (this.showHolder == "password_personal") {
				this.showHolder = null;
				this.showDialogPasswordPersonal();
			} else if (this.showHolder == "player_answer") {
				this.showHolder = null;
				this.showDialogPlayerAnswer();
			} else if (this.showHolder == "finish") {
				this.showHolder = null;
				this.$root.showFinish = true;
			}
		},
		secondsToHms(d) {
			var sec_num = parseInt(d);
			var hours = Math.floor(sec_num / 3600);
			var minutes = Math.floor((sec_num - hours * 3600) / 60);
			var seconds = sec_num - hours * 3600 - minutes * 60;

			if (hours < 10) {
				hours = "0" + hours;
			}
			if (minutes < 10) {
				minutes = "0" + minutes;
			}
			if (seconds < 10) {
				seconds = "0" + seconds;
			}
			return (hours != "00" ? hours + ":" : "") + (minutes != "00" ? minutes + ":" : "") + seconds;
		},
		applyTimerDecreaseStep(points) {
			if (points == 0) {
				return 0;
			}
			if (this.task.point_decrease_step <= 0 || this.task.time_limit <= 0) {
				return points;
			}
			let time = this.task.time_limit - (this.endTime - this.startTime) / 1000;
			if (time < 0) {
				return points;
			}
			let step = this.task.point_decrease_step + 1;
			let pointsPerStep = (points * 1) / step;
			return Math.round((Math.floor((time * 1) / ((this.task.time_limit * 1) / step)) + 1) * pointsPerStep);
		},
		async pasteClipboard() {
			try {
				const clipboardItems = await navigator.clipboard.read();
				for (const clipboardItem of clipboardItems) {
					for (const type of clipboardItem.types) {
						if (type.startsWith("image")) {
							const blob = await clipboardItem.getType(type);
							var file = new File([blob], this.task.id+"."+blob.type.split("/")[1], { lastModified: new Date().getTime(), type: blob.type });
							if (file) {
								const fr = new FileReader();
								fr.readAsDataURL(file);
								fr.addEventListener("load", () => {
									this.photoTemp = fr.result;
									this.attachment = file; // this is an image file that can be sent to server...
								});
							}
						}
					}
				}
			} catch (err) {
				console.error(err.name, err.message);
			}
		}
	}
};
</script>
