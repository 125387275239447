import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

/* eslint-disable */

if (localStorage.getItem("principal") && JSON.parse(localStorage.getItem("principal")).token) {
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem("principal")).token;
}

export default new Vuex.Store({
	state: {
		principal: (localStorage.getItem("principal") && JSON.parse(localStorage.getItem("principal"))) || {},
		notification: {
			title: null,
			content: {},
			show: false
		},
		timer: (localStorage.getItem("timer") && JSON.parse(localStorage.getItem("timer"))) || { h: 0, m: 0, s: 0 },
		player: (localStorage.getItem("player") && JSON.parse(localStorage.getItem("player"))) || {},
		currentCheckPoint: (localStorage.getItem("currentCheckPoint") && JSON.parse(localStorage.getItem("currentCheckPoint"))) || null,
		scoreHistories: (localStorage.getItem("scoreHistories") && JSON.parse(localStorage.getItem("scoreHistories"))) || [],
		doneOdt: (localStorage.getItem("doneOdt") && JSON.parse(localStorage.getItem("doneOdt"))) || [],
		isStarted: localStorage.getItem("isStarted") ? localStorage.getItem("isStarted") === 'true' : false,
		isCompletedStartArea: localStorage.getItem("isCompletedStartArea") ? localStorage.getItem("isCompletedStartArea") === 'true' : false,
		isPaused: localStorage.getItem("isPaused") ? localStorage.getItem("isPaused") === 'true' : false,
		isFinished: localStorage.getItem("isFinished") ? localStorage.getItem("isFinished") === 'true' : false,
		isInitChat: localStorage.getItem("isInitChat") ? localStorage.getItem("isInitChat") === 'true' : false,
		locale: localStorage.getItem("locale") ? localStorage.getItem("locale") : "en",
		playersPosition: (localStorage.getItem("playersPosition") && JSON.parse(localStorage.getItem("playersPosition"))) || [],
		showOtherPlayers: localStorage.getItem("showOtherPlayers") ? localStorage.getItem("showOtherPlayers") === 'true' : false,
	},
	mutations: {
		start(state, principal) {
			state.principal = principal;
			localStorage.setItem("principal", JSON.stringify(principal));
		},
		logout(state) {
			state.principal = {};
			state.timer = { h: 0, m: 0, s: 0 };
			state.player = {};
			state.currentCheckPoint = null;
			state.scoreHistories = [];
			state.doneOdt = [];
			state.isStarted = false;
			state.isCompletedStartArea = false;
			state.isPaused = false;
			state.isFinished = false;
			state.isInitChat = false;
			state.locale = "en";
			state.playersPosition = [];
			state.showOtherPlayers = false;
			delete axios.defaults.headers.common['Authorization'];
			localStorage.removeItem("principal");
			localStorage.removeItem("player");
			localStorage.removeItem("currentCheckPoint");
			localStorage.removeItem("scoreHistories");
			localStorage.removeItem("doneOdt");
			localStorage.removeItem("isStarted");
			localStorage.removeItem("isCompletedStartArea");
			localStorage.removeItem("isPaused");
			localStorage.removeItem("isFinished");
			localStorage.removeItem("timer");
			localStorage.removeItem("isInitChat");
			localStorage.removeItem("locale");
			localStorage.removeItem("playersPosition");
			localStorage.removeItem("showOtherPlayers");
		},
		showNotification(state, { title, content }) {
			state.notification.title = title;
			state.notification.content = content;
			state.notification.show = true;
		},
		hideNotification(state) {
			state.notification.show = false;
			state.notification.title = null;
			state.notification.content = {};
		},
		debug(state) {
			console.log("localStorage principal: " + localStorage.getItem("principal") + " state: " + JSON.stringify(state, null, 4));
		},
		setPlayer(state, player){
			state.player = player;
			localStorage.setItem("player", JSON.stringify(player));
		},
		setCurrentCheckPoint(state, checkPoint) {
			state.currentCheckPoint = checkPoint;
			localStorage.setItem("currentCheckPoint", JSON.stringify(checkPoint));
		},
		updateOdt(state, odt) {
			if (odt) {
				let principal = JSON.parse(localStorage.getItem("principal"));
				let index = principal.activity.on_demand_tasks.findIndex((x) => x.id == odt.id);
				if (index >= 0) {
					principal.activity.on_demand_tasks.splice(index, 1, odt);
					localStorage.setItem("principal", JSON.stringify(principal));
					state.principal = principal
				}
			}
		},
		setScoreHistory(state, scoreHistory){
			var scoreHistories = JSON.stringify(state.scoreHistories) == "[]" ? [] : JSON.parse(JSON.stringify(state.scoreHistories));
			if (scoreHistory != null) {
				scoreHistories.push(scoreHistory);
			}
			state.scoreHistories = scoreHistories;
			localStorage.setItem("scoreHistories", JSON.stringify(state.scoreHistories));
		},
		resetScoreHistory(state){
			state.scoreHistories = [];
			localStorage.removeItem("scoreHistories");
		},
		setTime(state, { timer, time }){
			if (time) {
				let principal = JSON.parse(localStorage.getItem("principal"));
				principal.time = time;
				localStorage.setItem("principal", JSON.stringify(principal));
				state.principal = principal

				localStorage.setItem("timer", JSON.stringify(timer));
				state.timer = timer
			}
		},
		setScore(state, score){
			if (score) {
				let principal = JSON.parse(localStorage.getItem("principal"));
				principal.score = (principal.score ? parseInt(principal.score) : 0) + parseInt(score);
				if (principal.score < 0) {
					principal.score = 0;
				}
				localStorage.setItem("principal", JSON.stringify(principal));
				state.principal = principal
			}
		},
		setToken(state, token) {
			var principal = state.principal;
			var dataToken = JSON.stringify(principal.dataToken) == "[]" ? [] : JSON.parse(JSON.stringify(principal.dataToken));
			let tokenExist = dataToken.find(x => x.id == token.id);
			if (tokenExist != null) {
				tokenExist.count = parseInt(tokenExist.count) + parseInt(token.count);
				let index = dataToken.findIndex(x => x.id == token.id);
				dataToken.splice(index, 1, tokenExist);
			} else {
				let keys = JSON.stringify(state.principal.keys) == "[]" ? [] : JSON.parse(JSON.stringify(state.principal.keys));
				token = {...token, ...keys.find(x => x.id == token.id)};
				if (token.lifetime > 0) {
					token.started = Date.now();
				}
				dataToken.push(token);
			}
			// dataToken = []
			principal.dataToken = dataToken;
			localStorage.setItem("principal", JSON.stringify(principal));
		},
		decreaseToken(state, tokens) {
			var principal = state.principal;
			var dataToken = JSON.stringify(principal.dataToken) == "[]" ? [] : JSON.parse(JSON.stringify(principal.dataToken));
			for (let i in tokens) {
				if (tokens[i].type == 0) {
					let tokenExist = dataToken.find(x => x.id == tokens[i].id);
					if (tokenExist != null) {
						tokenExist.count = parseInt(tokenExist.count) - parseInt(tokens[i].count);
						let index = dataToken.findIndex(x => x.id == tokens[i].id);
						if (tokenExist.count > 0) {
							dataToken.splice(index, 1, tokenExist);
						} else {
							dataToken.splice(index, 1);
						}
					}
				}
			}
			principal.dataToken = dataToken;
			localStorage.setItem("principal", JSON.stringify(principal));
		},
		doneCheckPoint(state, checkpointId) {
			var principal = state.principal;
			var doneCheckpoint = JSON.stringify(principal.doneCheckpoint) == "[]" ? [] : JSON.parse(principal.doneCheckpoint);
			if (checkpointId != null && !doneCheckpoint.includes(checkpointId)) {
				doneCheckpoint.push(checkpointId);
			}
			principal.doneCheckpoint = JSON.stringify(doneCheckpoint);
			localStorage.setItem("principal", JSON.stringify(principal));
		},
		doneOdt(state, odtId){
			var doneOdt = JSON.stringify(state.doneOdt) == "[]" ? [] : Array.isArray(state.doneOdt) ? state.doneOdt : JSON.parse(state.doneOdt);
			if (odtId != null && !doneOdt.includes(odtId)) {
				doneOdt.push(odtId);
			}
			// doneOdt = []
			state.doneOdt = doneOdt;
			localStorage.setItem("doneOdt", JSON.stringify(doneOdt));
		},
		setStarted(state, isStarted){
			state.isStarted = isStarted;
			localStorage.setItem("isStarted", isStarted);
		},
		setCompletedStartArea(state, isCompletedStartArea){
			state.isCompletedStartArea = isCompletedStartArea;
			localStorage.setItem("isCompletedStartArea", isCompletedStartArea);
		},
		setPaused(state, isPaused) {
			state.isPaused = isPaused;
			localStorage.setItem("isPaused", isPaused);
		},
		setFinished(state, isFinished){
			state.isFinished = isFinished;
			localStorage.setItem("isFinished", isFinished);
		},
		setInitChat(state, isInitChat){
			state.isInitChat = isInitChat;
			localStorage.setItem("isInitChat", isInitChat);
		},
		setLocale(state, locale){
			state.locale = locale;
			localStorage.setItem("locale", locale);
		},
		setPlayersPosition(state, playersPosition){
			state.playersPosition = playersPosition;
			localStorage.setItem("playersPosition", JSON.stringify(playersPosition));
		},
		setShowOtherPlayers(state, showOtherPlayers){
			state.showOtherPlayers = showOtherPlayers;
			localStorage.setItem("showOtherPlayers", showOtherPlayers);
		},
	},
	actions: {
		start({ commit }, principal) {
			return new Promise((resolve, reject) => {
				axios.post(
					"/api/v1/start",
					{
						qr_code: principal.qrCode
					}
				).then(response => {
					let principal = response.data;
					if (principal) {
						commit('start', principal);
						resolve({response:response, principal:principal});
						// Change baseURL with licensee url according to where the game is accessed
						axios.defaults.baseURL = principal.user_host.host;
					}
				}).catch(error => reject(error));
			});
		},
		logout({ commit }) {
			commit('logout');
		},
		debug({ commit }) {
			commit('debug');
		}
	},
	getters: {
		isAuthenticated: state => (state.principal && state.principal.player_id),
		tokensLength: state => (state.principal.dataToken && state.principal.dataToken.length)
	}
})
